import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import { CountUp } from "use-count-up";
// import HubspotForm from "react-hubspot-form";
import Container from "../components/container";
import Layout from "../components/layout";
import enjin_1_mp4 from "../assets/enjin_1_test2.mp4";
import enjin_1_webm from "../assets/enjin_1_test2.webm";
import enjin_1_poster from "../assets/enjin_1_test2_poster.jpg";
import kantonsspitalGraubuendenLogo from "../assets/kantonsspital-graubuenden_logo.svg";
import stadtspitalZuerichLogo from "../assets/stadtspital-zuerich_logo.svg";
import hirslandenLogo from "../assets/hirslanden_logo.svg";
import universitaetsspitalBaselLogo from "../assets/universitaetsspital-basel_logo.svg";
import kantonsspitalStGallenLogo from "../assets/kantonsspital-st-gallen_logo.svg";
import medbaseLogo from "../assets/medbase_logo.svg";
import doppellogoEpiKllLogo from "../assets/Doppellogo_EPI_KLL.svg";
import ukkbLogo from "../assets/ukbb_logo.svg";
import ksblLogo from "../assets/KSBL_Logo.svg";
import GHL from "../assets/Logo_GHL_RBG.svg";
import zollikerbergLogo from "../assets/Spital_Zollikerberg_logo.svg";
import adullamLogo from "../assets/adullam_logo.svg";
import IADLogo from "../assets/IAD_logo.svg";
import healthEngineLogo from "../assets/health-engine_logo.svg";
import healthEngineSchema from "../assets/health-engine_schema.svg";

import {
  videoBackgroundColorHack4,
  sectionPadding,
  buttonGroup,
  schemaRow,
  schema,
  containerPadding,
  leftHalfSection,
  backgroundImageRightTop,
  backgroundImageRightBottom,
  eyesContainer,
  imageContainerMarginTop,
  imageContainerMarginBottom,
  testimonialGrid,
  testimonial,
  testimonialAuthor,
  // hubSpotForm,
  keyFigures,
  flexRow,
  flexGrid,
  column,
  sectionVideo,
  sectionText,
  sectionTextNegativeMarginTop,
  logoRow,
  logoSmaller,
  logoBigger,
  containerPaddingLeft,
  containerPaddingRight,
  h1,
} from "./page.module.scss";

const IndexPage = () => {
  const videoRef = React.useRef(null);
  const [showFirstImage, setShowFirstImage] = React.useState(true);

  const [ref] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
    onChange: (inView) => {
      if (inView) {
        videoRef?.current && videoRef.current.play();
      } else {
        videoRef?.current && videoRef.current.pause();
      }
    },
  });

  const [refStats, inView] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
  });

  React.useEffect(() => {
    const timing = showFirstImage ? 2000 : 500;
    const intervalId = setInterval(() => {
      setShowFirstImage(!showFirstImage);
    }, timing);
    return () => clearInterval(intervalId);
  }, [showFirstImage]);

  return (
    <Layout
      pageTitle="health-engine"
      description="the i-engineers widmen sich seit 2002 der Softwareentwicklung in den Lösungsbereichen Enterprise-Content-Management (ECM), Workflow- und Prozessmanagement."
    >
      <main>
        <section
          className={`${"text-blue"} ${"background-spring-green"}`}
          style={{ position: "relative" }}
          ref={ref}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={enjin_1_poster}
            className={videoBackgroundColorHack4}
            ref={videoRef}
          >
            <source src={enjin_1_mp4} type="video/mp4" />
            <source src={enjin_1_webm} type="video/webm" />
          </video>
          <Container noPadding={true}>
            <div className={flexGrid}>
              <div className={column}>
                <div className={sectionVideo}>
                  {/* <video autoPlay loop muted playsInline>
                    <source src={enjin_1_mp4} type="video/mp4" />
                    <source src={enjin_1_webm} type="video/webm" />
                  </video> */}
                </div>
              </div>

              <div
                className={`${column} ${sectionText} ${containerPadding} ${sectionTextNegativeMarginTop}`}
              >
                <h1>Einfacher gesagt und getan.</h1>
                <p className="text-large">
                  health-engine, die flexible Gesamtlösung für die
                  Digitalisierung Ihrer Spitalprozesse.
                </p>
                <div className={buttonGroup}>
                  {/*  <a
                   href="https://p-p.swcode.io/demo/portal"
                   target="_blank"
                   rel="noreferrer"
                   className="button text-spring-green"
                 >
                   Patientenportal
                 </a>
                 <a
                   href="https://z-p.swcode.io/demo/portalzp"
                   target="_blank"
                   rel="noreferrer"
                   className="button text-spring-green"
                 >
                   Zuweiserportal
                 </a> */}

                  <Link
                    to="/the-i-engineers#kontakt"
                    className="button-outline"
                  >
                    Experten kontaktieren
                  </Link>
                </div>
              </div>
            </div>
          </Container>

          <section
            style={{ paddingTop: 30 }}
            className={`${"background-white"}`}
          >
            <Container>
              <div className={logoRow}>
                {/*<img src={zollernalb} alt="zollernalb" /> */}

                <img src={zollikerbergLogo} alt="Zollikerberg" />

                <img src={doppellogoEpiKllLogo} alt="Swiss EPI" />

                <img src={medbaseLogo} alt="medbase" />

                <img
                  src={kantonsspitalGraubuendenLogo}
                  alt="Kantonsspital Graubünden"
                />

                <img
                  src={stadtspitalZuerichLogo}
                  alt="Stadtspital Zürich"
                  className={logoBigger}
                />

                <img src={hirslandenLogo} alt="Hirslanden-Gruppe" />

                <img src={IADLogo} alt="Immanuel Albertinen Diakonie" />

                <img
                  src={universitaetsspitalBaselLogo}
                  alt="Universitätsspital Basel"
                />

                <img
                  src={kantonsspitalStGallenLogo}
                  alt="Kantonsspital St. Gallen"
                />
                <img
                  src={ukkbLogo}
                  alt="Universitäts-Kinderspital beider Basel"
                  className={logoSmaller}
                />
                <img src={adullamLogo} alt="Adullam Spital und Pflegezentren" />
                <img
                  src={ksblLogo}
                  alt="Universitäts-Kinderspital beider Basel"
                />

                <img src={GHL} alt="Gesundheitsholding Lüeneburg" />
              </div>
            </Container>
          </section>
        </section>

        <section
          id="architektur"
          className={`${sectionPadding} ${"text-blue"} ${"background-sky-blue"}`}
        >
          <Container>
            <div className={schemaRow}>
              <img src={healthEngineLogo} alt="health-engine Logo" />
              <h1 className="text-large">
                Das zentrale System, das medizinische und administrative Daten
                aus allen Fachanwendungen zusammenführt und zugänglich macht.
              </h1>
            </div>
            <img
              src={healthEngineSchema}
              alt="health-engine"
              className={schema}
            />
            <p className="text-medium">
              Ein ungehinderter Datenaustausch und eine übergreifende Steuerung
              der Therapieprozesse ermöglichen eine effiziente interdisziplinäre
              Zusammenarbeit zwischen Abteilungen, Funktionsbereichen und
              Standorten.
            </p>
          </Container>
        </section>

        <section
          id="clinical-data-repository"
          className={`${"background-blue"} ${"text-sky-blue"} ${sectionPadding}`}
        >
          <StaticImage
            src="../assets/clinical-data-repository_framed.png"
            alt="Clinical Data Repository"
            placeholder="none"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            objectFit="contain"
            objectPosition="right top"
            className={backgroundImageRightTop}
          />
          <Container>
            <div className={`${sectionText} ${leftHalfSection}`}>
              <h1>Übersicht ist fast alles.</h1>
              <p className="text-large">
                Das <span className="underline">Clinical Data Repository</span>{" "}
                erlaubt eine konsolidierte Sicht auf Patienteninformationen im
                Patienten- und Fallkontext.
              </p>
              <div className={buttonGroup}>
                <Link
                  to="/clinical-data-repository"
                  className="button-outline sky-blue"
                >
                  Weitere Infos
                </Link>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="interoperabilitaetsplattform"
          className={`${"background-rose-pink"} ${"text-orange"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTHEAST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_SOUTHEAST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1>Hält zusammen, was es verspricht.</h1>
                <p className="text-large">
                  Die Kommunikations- und{" "}
                  <span className="underline">
                    {" "}
                    Interoperabilitäts&shy;plattform
                  </span>{" "}
                  verbindet Krankenhäuser, den ambulanten Sektor und unser
                  Partner-Netzwerk.
                </p>
                <div className={buttonGroup}>
                  <Link
                    to="/interoperabilitaetsplattform"
                    className="button-outline orange"
                  >
                    Weitere Infos
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="integrierte-prozesssteuerung"
          className={`${"background-green"} ${"text-rose-pink"} ${sectionPadding}`}
        >
          <StaticImage
            src="../assets/prozesssteuerung_framed.png"
            alt="Clinical Data Repository"
            placeholder="none"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            objectFit="contain"
            objectPosition="right bottom"
            className={backgroundImageRightBottom}
          />
          <Container>
            <div className={`${sectionText} ${leftHalfSection}`}>
              <h1>Für gesunde Prozesse täglich anwenden.</h1>
              <p className="text-large">
                Mit der{" "}
                <span className="underline">
                  integrierten Prozess-Steuerung
                </span>{" "}
                werden klinische Arbeitsabläufe automatisiert und gesteuert.
              </p>
              <div className={buttonGroup}>
                <Link to="/prozessmanager" className="button-outline rose-pink">
                  Weitere Infos
                </Link>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="module"
          className={`${"background-spring-green"} ${"text-purple"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div className={column}>
                <div className={imageContainerMarginBottom}>
                  <StaticImage
                    src={`../assets/module.png`}
                    alt="health-engine Module"
                    placeholder="none"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1>Individuali&shy;sierbar, skalierbar, wunderbar.</h1>
                <p className="text-large">
                  Durch verschiedene <span className="underline"> Module</span>{" "}
                  kann health-engine leicht an Ihre Bedürfnisse angepasst
                  werden.
                </p>
                <div className={buttonGroup}>
                  <Link to="/module" className="button-outline purple">
                    Weitere Infos
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="healthcare-oekosystem"
          className={`${"background-purple"} ${"text-spring-green"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1>Ja, auch das kann sie.</h1>
                <p className="text-large">
                  Um den stetig steigenden Anforderungen im komplexen Umfeld von
                  Krankenhäusern gerecht zu werden, haben wir ein komplettes{" "}
                  <span className="underline">Healthcare Ökosystem</span>{" "}
                  entwickelt.
                </p>
                <div className={buttonGroup}>
                  <Link
                    to="/healthcare-oekosystem"
                    className="button-outline spring-green"
                  >
                    Weitere Infos
                  </Link>
                </div>
              </div>
              <div className={column}>
                <div className={imageContainerMarginTop}>
                  <StaticImage
                    src={`../assets/partner.png`}
                    alt="Healthcare Ökosystem"
                    placeholder="none"
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="referenzen"
          className={`${"background-sky-blue"} ${"text-purple"} ${sectionPadding}`}
        >
          <Container>
            <div className={sectionText}>
              <h1>Was unsere Kunden über health-engine sagen.</h1>
            </div>
            <div className={testimonialGrid}>
              <div className={testimonial}>
                <p className="text-large">
                  «Dank der durchdachten und innovativen health-engine von the
                  i-engineers können wir unseren Ärzt:innen und Pflegenden
                  optimierte Apps mit allen nötigen Informationen und Funktionen
                  zur Verfügung stellen und damit Aufwände und Kosten
                  reduzieren.»
                </p>
                <div className={testimonialAuthor}>
                  <p className="text-medium">
                    – Juri Toffol, Universitätsspital Basel
                  </p>
                </div>
              </div>

              <div className={testimonial}>
                <p className="text-large">
                  «Die health-engine hilft uns die Vielfalt der medizinischen
                  Daten aus allen Standorten und Umsystemen zu konsolidieren und
                  über einen single Point of Communication sicher und effizient
                  mit internen und externen Leistungserbringern zu teilen.»
                </p>
                <div className={testimonialAuthor}>
                  <p className="text-medium">
                    – Didier Voegelin, Kantonsspital Baselland
                  </p>
                </div>
              </div>

              <div className={testimonial}>
                <p className="text-large">
                  «Die health-engine sammelt alle medizinischen Informationen
                  aus den verschiedenen KIS, RIS, LIS, PACS usw. und stellt
                  diese unter anderem als Notfallsystem allen Mitarbeiter:innen
                  stationär oder mobil zur Verfügung. Mit dem Modul für den
                  medizinischen Dienst werden Anfragen und Rückweisungen in
                  kürzester Zeit mit allen nötigen Daten halbautomatisch
                  beantwortet.»
                </p>
                <div className={testimonialAuthor}>
                  <p className="text-medium">
                    – Andreas Rygol, Immanuel Albertinen Diakonie
                  </p>
                </div>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/watch?v=ZkFEgx0OW9A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/Interview-Video-Hero-Andreas-vs1.jpg"
                    alt="Interview mit Andreas Rygol"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>
              </div>

              <div className="text-large italic">
                Das Ergebnis der Kundenbefragung 2023 in der CH und DE ist
                ausgezeichnet: 4.6/5 Sternen
              </div>
            </div>
          </Container>
        </section>

        <section
          className={`${"background-green"} ${"text-sky-blue"} ${keyFigures}`}
        >
          <Container>
            <div className={flexRow} ref={refStats}>
              <div className={column}>
                <p className="text-large">über</p>
                <div className={h1}>
                  <CountUp
                    isCounting={inView}
                    end={300}
                    duration={0.5}
                    decimalPlaces={0}
                    thousandsSeparator={"’"}
                  />
                </div>

                <p className="text-large">Kliniken</p>
              </div>
              <div className={column}>
                <p className="text-large">über</p>
                <div className={h1}>
                  <CountUp
                    isCounting={inView}
                    end={100000}
                    duration={0.5}
                    decimalPlaces={0}
                    thousandsSeparator={"’"}
                  />
                </div>

                <p className="text-large">User</p>
              </div>
              <div className={column}>
                <p className="text-large">über</p>
                <div className={h1}>
                  <CountUp
                    isCounting={inView}
                    end={20}
                    duration={0.5}
                    decimalPlaces={0}
                    thousandsSeparator={"’"}
                  />
                </div>

                <p className="text-large">Jahre Erfahrung</p>
              </div>
            </div>
          </Container>
        </section>

        <section
          id="demo-und-broschueren"
          className={`${"background-sun-yellow"} ${"text-blue"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_STRAIGHT.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_EAST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1>Starten Sie heute.</h1>
                <p className="text-large">
                  Jetzt ist der richtige Zeitpunkt für einen Wechsel. Unsere
                  Experten beraten Sie gerne.
                </p>
                <div className={buttonGroup}>
                  {/*  <a
                   href="https://p-p.swcode.io/demo/portal"
                   target="_blank"
                   rel="noreferrer"
                   className="button text-spring-green"
                 >
                   Patientenportal
                 </a>
                 <a
                   href="https://z-p.swcode.io/demo/portalzp"
                   target="_blank"
                   rel="noreferrer"
                   className="button text-spring-green"
                 >
                   Zuweiserportal
                 </a> */}

                  <Link
                    to="/landingpages/booklets/"
                    className="button-outline blue"
                  >
                    h-e Broschüren
                  </Link>

                  <Link
                    to="/the-i-engineers#kontakt"
                    className="button-outline blue"
                  >
                    Experten kontaktieren
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section
          id="newsletter"
          className={`${"background-purple"} ${"text-sun-yellow"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1 style={{ marginBottom: 16 }}>Newsletter</h1>
                <p className="text-large">
                  Bleiben Sie mit unserer Firma auf dem Laufenden. Wir freuen
                  uns über Ihre Teilnahme.
                </p>

                <div className={buttonGroup}>
                  <Link
                    to="landingpages/newsletter-anmeldung/"
                    className="button-outline sun-yellow"
                  >
                    Zur Newsletter Anmeldung
                  </Link>
                </div>
              </div>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_WEST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTH.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
